import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

const useStyles = makeStyles({
  ...styles,
  imageInfo5: {
    width: "100%",
    height: "300px",
    boxShadow: "0 8px 6px -6px black",
    display: "block",
    borderRadius: "3px",
    position: "relative",
    top: "120px",

    "@media(min-width: 768px) and (max-width: 1024px)": {
      width: "100%",
      height: "310px",
      position: "relative",
      top: "40px",
    },
    "@media(max-width: 500px)": {
      maxWidth: "100%",
      maxHeight: "210px",
      display: "flex",
      marginLeft: "30px",
    },
  },
});
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section2)}>
      <div>
        <Grid container>
          <Grid item lg={6} xs={12} sm={10} md={7}>
            <div className={classes.containerLeak}>
              <Grid item lg={12} xs={12} sm={10} md={12}>
                <h2 className={classes.title3}>WHY CHOOSE US</h2>
              </Grid>
              <Grid item lg={10} xs={10} sm={10} md={12}>
                <h2 className={classes.title1}>
                  24/7 COMMERCIAL PLUMBING SERVICE
                </h2>
                <p className={classes.paragraph}>
                  At GoPro Plumbing, we are not only experienced and trained
                  plumbers, but we also strive to provide amazing customer
                  service. We make sure that all our clients are satisfied - we
                  strive to fix issues as quickly and as efficiently as
                  possible. If you choose us, you will have access to:
                </p>

                <ul className={classes.paragraph}>
                  <h2 className={classes.title1} style={{ fontSize: "30px" }}>
                    Insured & Licensed
                  </h2>
                  <li>
                    Choosing an insured and licensed commercial plumber in
                    Mississauga is essential to get the job completed to the
                    best standard. You can rest assured that at GoPro Plumbing,
                    we are licensed and insured to take care of your commercial
                    plumbing needs.
                  </li>
                  <li>
                    Modern materials and technological solutions with
                    satisfaction guaranteed.
                  </li>
                  <li>
                    100% dedication and quality workmanship for every project,
                    no matter its scope or location.
                  </li>
                  <li>
                    Emergency service available 24/7 for all your plumbing
                    repairs that cannot wait
                  </li>
                  <li>
                    We are dedicated to providing the very best service to all our clients, no matter how big or small the job is. When you contact GoPro Plumbing with a plumbing issue, our team of plumbing experts can be relied on to provide a fast response and the best service.

                  </li>
                </ul>
              </Grid>
            </div>
          </Grid>
          <Grid item lg={5} xs={12} sm={10} md={7}>
            <Grid
              container
              justify="right"
              direction="column"
              alignItem="right"
              alignContent="right"
            >
              <Grid item lg={10} xs={10} sm={12} md={12}>
                <div>
                  <iframe
                    className={classes.imageInfo5}
                    src="https://www.youtube.com/embed/_1GnTnaxS1I"
                    frameBorder="0"
                    allow="encrypted-media; gyroscope;"
                    allowFullScreen
                    title="Leak detection system"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
