import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Grid from "@material-ui/core/Grid";

import Certified from "../../assets/emergency-plumber-4.svg";

import styles from "../../jss/productStyle.js";

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
  },
  section2: {
    padding: "70px 0",
  },
  paragraph2: {
    color: "#212121",
    fontSize: "16px",
    fontFamily: "poppins",
    fontWeight: "400",
    lineHeight: "32px",
    textAlign: "left",
    "@media screen and (min-width:1920px)": {
      marginTop: "10px",
    },
  },
  imageContainer: {
    padding: "60px 60px 0px 60px",
    marginTop: "40px",
    "@media screen and (max-width:500px)": {
      padding: "0px 60px 0px 60px",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "1",
    },
  },
  orderContainer: {
    "@media screen and (max-width:500px)": {
      order: "2",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      order: "2",
    },
  },
});

export default function ProductSection() {
  const classes = useStyles();

  return (
    <div>
      <Grid
        container
        justify="center"
        alignContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item lg={3} xs={10} sm={5} md={5}>
          <div align="center" className={classes.imageContainer}>
            <Certified alt="toronto-plumber" height="100%" width="100%" />
          </div>
        </Grid>

        <Grid item xs={10} sm={10} md={10} lg={7}>
          <h2 className={classes.title}>Mississauga Plumber </h2>
          <p className={classes.paragraph2}>
            Keeping your commercial buildings up and running is essential when
            operating a successful business. Building issues can have a
            significant impact on your ability to run your company, resulting in
            lost productivity and a poor customer experience. Plumbing problems
            at your commercial building can be especially disruptive and make
            your business premises unusable.
          </p>
          <p className={classes.paragraph2}>
            Commercial buildings receive so much wear and tear, and your
            plumbing system will be in constant use throughout the day. Whether
            you operate a manufacturing business that relies on water to produce
            goods or you own a store with customer restrooms, keeping your
            building’s plumbing systems in perfect working order is essential.
          </p>
          <p className={classes.paragraph2}>
            Every business needs a commercial plumber they can depend on to keep
            their plumbing systems working correctly at all times. Don’t let
            commercial building plumbing issues stand in the way of your
            business’s success. At GoPro Plumbing, we offer the reliable,
            responsive commercial plumbing service in Mississauga that your
            business needs.
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
