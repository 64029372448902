import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

import Design from "../../assets/plumbingServices/plumbing-services.svg";
import Product from "../../assets/plumbingServices/plumbing-services-2.svg";
import Install from "../../assets/plumbingServices/plumbing-services-3.svg";
import Inspection from "../../assets/plumbingServices/plumbing-services-4.svg";

const useStyles = makeStyles({
  ...styles,

  helpContainer: {
    "@media(min-width: 1950px)": {
      padding: "0px 300px 0px 300px",
    },
  },
});
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div className={classNames(classes.section, classes.sectionDark)}>
      <div style={{ padding: "60px 0px 80px 0px" }}>
        <Grid container justify="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              PLUMBING SERVICES
            </h2>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            md={10}
            lg={7}
            style={{ background: "none" }}
          >
            <h2
              className={classes.title}
              style={{ fontSize: "25px", color: "#0099CC" }}
            >
              {" "}
              GoPro Plumbing Commercial Plumbing Services in Mississauga{" "}
            </h2>
            <p
              className={classes.paragraph2}
              style={{ color: "inherit", fontFamily: "poppins2" }}
            >
              Here at GoPro Plumbing, we are proud to offer all the commercial
              plumbing services businesses in Mississauga need to keep plumbing
              issues at bay.
            </p>
            <p
              className={classes.paragraph2}
              style={{ color: "inherit", fontFamily: "poppins2" }}
            >
              Our expert team is on hand to help with all aspects of commercial
              building plumbing for companies of all sizes. Here are the
              services we offer to prevent and treat any plumbing issues your
              commercial building may be experiencing:
            </p>
            <p
              className={classes.paragraph2}
              style={{ color: "inherit", fontFamily: "poppins2" }}
            >
              Every business needs a commercial plumber they can depend on to
              keep their plumbing systems working correctly at all times. Don’t
              let commercial building plumbing issues stand in the way of your
              business’s success. At GoPro Plumbing, we offer the reliable,
              responsive commercial plumbing service in Mississauga that your
              business needs.
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
          align="center"
          className={classes.helpContainer}
        >
          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Design alt="toronto-plumbers" height="128px" width="128px" />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Design
                  </h3>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Product
                    alt="toronto-plumbers"
                    height="128px"
                    width="128px"
                  />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    PRODUCT SELECTION
                  </h3>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Install
                    alt="toronto-plumbers"
                    height="128px"
                    width="128px"
                  />
                </div>
              </Grid>
              <Grid item lg={11} md={10} sm={11} xs={11}>
                <Grid item lg={8} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    INSTALLATION
                  </h3>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} xs={10} sm={10} md={10}>
            <Grid
              container
              justify="center"
              direction="column"
              alignItem="center"
              alignContent="center"
            >
              <Grid item lg={12} xs={12} sm={12} md={12}>
                <div className={classes.imageContainer} align="center">
                  <Inspection
                    alt="toronto-plumbers"
                    height="128px"
                    width="128px"
                  />
                </div>
              </Grid>
              <Grid item lg={12} md={10} sm={11} xs={11}>
                <Grid item lg={10} md={10} sm={11} xs={11}>
                  <h3 align="center" className={classes.title2}>
                    Inspection
                  </h3>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
