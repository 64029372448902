import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Grid from "@material-ui/core/Grid";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../jss/landing-webdesign.js";

const useStyles = makeStyles({
  ...styles,

  image2: {
    width: "50%",
    height: "400px",
    boxShadow: "0 8px 6px -6px black",
    display: "block",
    borderRadius: "3px",
    position: "relative",
    top: "40px",

    "@media(min-width: 768px) and (max-width: 1024px)": {
      width: "50%",
      height: "350px",
      position: "relative",
      top: "40px",
    },
    "@media(max-width: 500px)": {
      width: "auto",
      height: "210px",
      display: "flex",
    },
  },
});
export default function SectionCards() {
  const classes = useStyles();

  return (
    <div style={{ padding: "60px 0px 0px 0px" }}>
      <div className={classNames(classes.section, classes.sectionDark)}>
        <Grid container justify="center" alignItem="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              RESIDENTIAL SERVICES
            </h2>
          </Grid>
          <Grid item lg={5} xs={10} sm={10} md={7}>
            <p className={classes.paragraph}>
              GoPro Plumbing has extensive knowledge and experience in
              commercial and residential plumbing projects. We know that both
              require a different approach, one that is conscious of the
              specifics for each type of property. So whether for your home or
              commercial space, contact GoPro Plumbing to use a variety of
              services including:
            </p>

            <ul className={classes.paragraph}>
              <li>Fixture upgrades & faucet repair</li>
              <li>Plumbing and drain services</li>
              <li>Watermain, riser, and kitec replacement</li>
              <li>Drain cleaning, clogged toilets, and inspections</li>
              <li>Troubleshooting</li>
              <li>New construction</li>
              <li>Backwater valve installation</li>
            </ul>
            <p className={classes.paragraph}>
              If not quickly resolved, the smallest plumbing issue like a
              clogged drain can cause major problems to your home. As a licensed
              plumbing company in Mississauga, we are working around the clock
              to satisfy our clients’ needs. Use our expertise and rich
              experience to return your home or business to normal as soon as
              possible.
            </p>
          </Grid>
        </Grid>
        <Grid container justify="center" alignItem="center">
          <Grid item lg={10} xs={10} sm={10} md={7}>
            <h2 align="center" className={classes.title}>
              COMMERCIAL PROJECTS
            </h2>
          </Grid>
          <Grid item lg={5} xs={10} sm={10} md={7}>
            <p className={classes.paragraph}>
              Our plumber Mississauga services includes plumbing for commercial
              spaces. We provide modern and efficient solutions for commercial
              spaces of all kinds. We are experienced with helping business
              combat COVID-19 in bathrooms and kitchens. Reach out to our team
              today to get a quote for your next plumbing job in Mississauga.
            </p>
            <p className={classes.paragraph}>
              If you are an owner or operator of an educational center, mall or
              office, reach out to GoPro Plumbing for improving your plumbing
              systems at. We offer the following services:
            </p>
            <ul className={classes.paragraph}>
              <li> Watermain replacement</li>
              <li>Kitec plumbing and fittings removal</li>
              <li>
                {" "}
                Riser replacements for closed-hoop hydronic riser systems and
                header piping
              </li>
              <li> Plumbing fixture upgrades to modernize your facilities</li>
              <li>
                PhynPlus technology smart water detection system provides leak
                detection, frozen pipe detection alerts, water usage statistics,
                and automatic water shut-off if a leak is detected.
              </li>
              <li>
                Commercial plumbing troubleshooting to identify potential
                plumbing issues and prevent them before they cause problems.
              </li>
              <li>
                {" "}
                New construction plumbing to help plan and design the plumbing
                for new construction projects.
              </li>
            </ul>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
